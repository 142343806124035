const ProjectData=[
    {
        title:"SyncMe",
        tech:"Python | GAN | MoviePy",
        text1:"• Engineered a sophisticated pipeline capable of synchronizing facial movements in videos with user-selected audio inputs.",
        text2:"• Leveraged the Wav2Lip framework and integrated cutting-edge enhancements like GFPGAN v1.4, elevating the project’s capabilities in generating high-quality lip-synced content.",
        view:"",
        source:"https://github.com/anishhguptaa/SyncMe"
    },
    {
        title:"Movies Engine",
        tech:"KNN | scikit-learn | Numpy",
        text1:"• Developed a sophisticated movie recommendation engine using the KNN algorithm.",
        text2:"• Adopted the Item-Based Collaborative Filtering methodology, prioritizing its superior consistency and accuracy over User-Based Collaborative Filtering.",
        view:"",
        source:""
    },
    {
        title:"AWS Resource Bot",
        tech:"Python | AWS | Boto3",
        text1:"• Leveraged an AWS Lambda function implemented in Python to analyze Amazon CloudWatch metrics.",
        text2:"• Deployed an automated bot to proactively manage resource allocation on Amazon EC2 instances by autonomously deleting Orphaned Elastic Load Balancers after 7 days of inactivity, optimizing cost efficiency and streamlining resource utilization.",
        view:"",
        source:""
    }
]

export default ProjectData