const EducationData=[
    {
        title:"Bachelors of Engineering (B.E.), CSE",
        institute:"Chitkara University",
        location:"Punjab, India",
        date:"2019-2023"
    },
    {
        title:"Senior Secondary Education",
        institute:"Patel Public School, CBSE",
        location:"Rajpura",
        date:"2017-2019"
    },
    {
        title:"Secondary Education",
        institute:"Holy Angels School, ICSE",
        location:"Rajpura",
        date:"2017"
    }
]

export default EducationData