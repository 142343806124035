import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./NavbarStyles.css"
import {FaBars, FaTimes} from 'react-icons/fa'

const Navbar = () => {
    const [click, setClick]=useState(false)
    const handleClick = () => setClick(!click)

    const [navColour, setNavColor]=useState(false)
    const changeNavColor =()=> {
        if(window.scrollY>=100){
            setNavColor(true)
        }else{
            setNavColor(false)
        }
    }
    window.addEventListener("scroll", changeNavColor)
    return (
    <div className={navColour?"header header-bg":"header"}>
        <div className="header-content">
            <Link to={"/"}>
                <h1>Portfolio</h1>
            </Link>
            <ul className={click ? 'nav-menu active':'nav-menu'}>
                <li>
                    <Link to={"/"}>Home</Link>
                </li>
                <li>
                    <Link to={"/about"}>About</Link>
                </li>
                <li>
                    <Link to={"/projects"}>Projects</Link>
                </li>
                <li>
                    <Link to={"/contact"}>Contact</Link>
                </li>
            </ul>
            <div className='hamburger' onClick={handleClick}>
                {click? 
                (<FaTimes size={20} style={{color: "#fff"}}/>):
                (<FaBars size={20} style={{color: "#fff"}}/>)}
            </div>
        </div>
    </div>
  )
}

export default Navbar