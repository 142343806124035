import React from 'react'
import HeroImg from '../Components/HeroImg'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

const Home = () => {
  return (
    <>
        <Navbar/>
        <HeroImg/>
        <Footer/>
    </>
  )
}

export default Home