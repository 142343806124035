const WorkData=[
    {
        title:"AI Engineer",
        company:"OpeninApp",
        location:"Bengaluru",
        date:"August 2023 - Present"
    },
    {
        title:"Credit Analytics Intern",
        company:"MPOWER Financing",
        location:"Bengaluru",
        date:"June 2022 - November 2022"
    },
    {
        title:"Machine Learning Intern",
        company:"Foxmula",
        location:"",
        date:"June 2020 - July 2020"
    }
]

export default WorkData